/* BACKGROUND ROW */

.thanksRootHome {
  height: auto;
  background-color: #000;
  // background-color: theme-color("bgColor");
  // background: url("../../../../assets/imgs/photos/homePage.png");
  // background-repeat: no-repeat;
  // background-position: center top;
  // background-size: cover;
}

/* CONTENT */
.thanksRootHome-inside {
  display: flex;
  width: 100%;
}

// @media (min-width: 2000px) and (max-height: 950px) {
//   .thanksRootHome {
//     padding-top: 130px;
//   }
// }

// @media (max-height: 800px) {
//   .thanksRootHome {
//     padding-top: 130px;
//   }
// }

@media (max-width: 991px) {
  .thanksRootHome {
    height: auto;
    padding: 0 0 0 0;
    background-size: cover;
    background-position: center;
  }
}