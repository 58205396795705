.thanksRootParagraph {
  background-color: theme-color("bgColor");
  // height: 50vh;
  max-width: none;
  // padding-bottom: 100px;
  // padding-top: 100px;
  // padding-bottom: 200px;
  // display: flex;
  // justify-content: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.thanksRootParagraph-inside {
  max-width: 80%;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .thanksRootParagraph {
    height: auto;
    padding-bottom: 5vw;
    padding-top: 0;

  }
  .thanksRootParagraph-inside {
    max-width: 100%;
    margin: 0 auto;
  }
}