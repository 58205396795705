.RootAboutUs {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: 1440px;
    padding-top: 5vw;
    // padding-bottom: 200px;
    width: 90%;

}

@media (max-width: 991px) {
    .RootAboutUs {
        height: auto;
        padding-top: 25vw;
        // padding-bottom: 15vw;
    }
}