.RootVip {
  background-color: theme-color("bgColor");
  height: auto;
  max-width: none;
  margin-top: 1vw;
  // padding-bottom: 100px;
  // padding-top: 100px;
  // padding-bottom: 200px;
  // display: flex;
  // justify-content: center;

}
.RootVip-inside {
  max-width: 80%;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .RootVip {
    height: auto;
    padding-bottom: 20%;
    padding-top: 0;

  }
  .RootVip-inside {
    max-width: 100%;
    margin: 0 auto;
  }
}