.RootProgram {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: none;
    padding-top: 5vw;
    padding-bottom: 5vw;
}

.RootProgram-inside {
    max-width: 80%;
    margin: 0 auto;
}

@media (max-width: 991px) {
    .RootProgram {
        height: auto;
        padding-left: 0;
        padding-right: 0;
        padding-top: 15vw;
        padding-bottom: 15vw;
    }
    .RootProgram-inside {
        max-width: 100%;
        margin: 0 auto;
    }
}