.maintenanceRoot {
  display: flex;
}
.maintenanceContainer {
  display: flex;
  background-image: url("./assets/img/photos/header.jpg"); // BACKGROUND IMAGE
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
}
.maintenanceText {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
}
.maintenanceText .card {
  width: 50%;
  height: auto;
  text-align: center;
  display: flex;
  background-color: rgba($color: #ce0000, $alpha: 0.05);
  color: #fff;
  font-family: "Kanit";
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.cardTitle {
  font-size: 30px;
}

@media (max-width: 991px) {
  .maintenanceText .card {
    width: 80%;
  }
}
