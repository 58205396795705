.RootGift {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: 100%;
    padding-top: 10vw;
    padding-bottom: 2vw;
}
.RootGift-inside {
    max-width: 80%;
    margin: 0 auto;
}

@media (max-width: 991px) {
    .RootGift {
        padding-top: 0;
        padding-bottom: 15vw;

        height: auto;
    }
}