.backButton {
  position: relative;
  top: -50px;
  width: 70px;
  height: 70px;
  font-size: 2em !important;
  line-height: 35px;
  background-color: transparent !important;
  border-radius: 50%;
  color: theme-color("primary");
  transition: 0.3s !important;
}
.backButton:hover {
  background-color: theme-color("primary") !important;
  color: #fff;
}
#buttonBack {
  transition: top 0.3s;
}
