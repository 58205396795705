.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

body {
  background-color: #000;
}





@media (max-width: 991px) {
  #inicio {
    width: 100% !important;
  }
}

@media (max-height: 500px) {
  body {
    /* overflow: initial; */
  }
}