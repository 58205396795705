.vipSectionRow {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: #fff;
}

.vipCol {
  height: 100%;
  width: 100%;
}


// .responsiveEmbed {
//   margin-top: 3vw;
// }

@media (min-width: 992px) {
  .vipCol:nth-child(1) {
    padding-right: 2vw;
  }
  .vipCol:nth-last-child(1) {
    padding-left: 2vw;
  }
}

@media (max-width: 991px) {
.vipColCOntent {
  margin-top: 10vw;
}
.joinUsImg  {
  margin: 5vw auto 0 auto;
}
.vipCol .pText, .vipCol .h2Title {
  margin-top: 5vw;
  text-align: center !important;
}
}