.programSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.programColImg {
    text-align: left;
}

.programImgCol {
    display: flex;
    align-items: flex-start;
}

.programImg {
    width: 100%;
    margin-top: 100px;
    position: sticky;
    top: 20px;

    margin-bottom: 3vw;
}

.textSquareCol {
    margin-bottom: 3vw;
}

.programContent .textSquare {
    padding: 40px;
    margin-bottom: 50px;
    text-align: left;

    position: relative;
    // border-bottom: 1px solid transparent;
    // border-top: 1px solid transparent;
    // border-image: url(../../../../assets/img/icons/Linea---Twerkealo.svg) 30 round;

}

.programContent .textSquare:nth-last-child(1) {
    margin-bottom: 0;


}

.programContent .h3Title {
    margin-bottom: 30px;
}

.programContent .pText {
    text-align: left;
}


.programSubtitle {
    width: 80%;
    margin-top: 50px !important;
    margin: 0 auto;
}

.programContent .whiteLine {
    position: relative;
    top: 0;
}

@media (max-width: 991px) {
    .programColImg {
        padding-left: 0;
        padding-right: 0;
    }

    .programImg {
        margin-top: 10vw;
        margin-bottom: 10vw;
    }

    .programContent {
        margin-left: 0;
        // padding-left: 10%;
        // padding-right: 10%;
    }

    .programContent .pText {
        margin-top: 6vw;

        text-align: center;
    }

    .programContent .textSquare {
        padding: 7vw;
    
    }

}