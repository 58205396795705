.poweredSection {
  margin-top: 30px;
  padding-bottom: 10px;
  z-index: 100;
}

.powered {
  font-family: "League Gothic";
  font-size: 18px;
  letter-spacing: 1.3px;
  font-weight: 500;
  display: block;
  text-align: center;
  color: #fff;

  transition: 0.3s;
}

.poweredSection .hrLine {
  width: 50px;
  height: 1px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: theme-color("primary");
}

#suprive {
  color: theme-color("priamry") !important;
}

.powered a {
  -webkit-text-decoration: none;

  text-decoration: none;
  color: theme-color("primary") !important;
}

.powered a:link {
  -webkit-text-decoration: none;

  text-decoration: none;
  color: theme-color("primary") !important;
}

@media (max-width: 1130px) and (min-width: 991px) and (max-height: 700px) {
  .powered {
    position: relative;
    left: -30px;
  }
}

@media (max-width: 1130px) and (min-width: 991px) and (max-height: 700px) {
  .powered {
    position: relative;
    left: -30px;
  }
}

@media (min-width: 991px) and (max-height: 600px) {
  .powered {
    position: relative;
    left: -30px;
  }
}

@media (max-width: 991px) {
  .poweredSection {
    padding-top: 0;
    // padding-bottom: 70px;
  }
}