.rootTerms {
  background-color: theme-color("bgColor");
  //   padding: 50px;
}
.termsContainer {
  padding-top: 150px;
  color: theme-color("textColor");
}
.termsContent h2,
.termsContent h4 {
  color: #fff;
}

.termsContent h4 {
  margin-top: 60px;
}

@media (max-width: 700px) {
  .rootTerms {
    padding-right: 25px;
    padding-left: 25px;
  }
}
