.thanksRootGift {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: 100%;
    padding-top: 5vw;
    padding-bottom: 5vw;
}
.thanksRootGift-inside {
    max-width: 80%;
    margin: 0 auto;
}

@media (max-width: 991px) {
    .thanksRootGift {
        padding-top: 0;
        padding-bottom: 15vw;

        height: auto;
    }
}