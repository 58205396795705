.thanksButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thanksButtongift{
  
    padding: 20px 60px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.5s;
    letter-spacing: 1.82px;
    -webkit-text-decoration: none !important;

    text-decoration: none !important;

    font-family: 'league-gothic';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    //  line-height: 75px;
    /* or 469% */

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
}

@media(max-width: 991px) {
    .thanksButtongift{
        padding: 2vw 4vw;
        margin-right: 0 !important;
        font-size: 4vw;
    }
}