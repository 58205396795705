.aboutUsSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

// .aboutUsCol {
//     min-height: 500px;
// }

// .aboutUsTextContent {
//     min-height: 500px;
// }



.aboutUsImg {
    width: 70%;
    transform: rotate(-3deg) !important;
    margin-bottom: 90px;
    height: 100%;
}

.aboutUsTitle {
    // position: absolute;
    // left: 100px;
    // top: -150px;
    margin-bottom: 3vw;
}



.aboutUsCol .pText {
    margin-top: 40px;
    text-align: left;
}




.aboutUsCol .whiteLine {
}

.whiteLineOlivia {
    bottom: -50px;

}

.whiteLineLucia {
    bottom: -100px;
}

@media (max-width: 991px) {
    .aboutUsTitle {
        position: relative;
        left: 0;
        top: 0;
        // padding-bottom: 50px;
    }

    .aboutUsImg {
        transform: rotate(0) !important;
        margin-bottom: 50px;
    }

    .aboutUsOlivia {
        margin-top: 50px;
    }

    .aboutUsLucia {
        margin-top: 20vw;
    }

    .whiteLineLucia {
        bottom: -50px;
    }
}