.classCardStyle {
  background-color: transparent;
  border: none;
  margin-left: 0 !important; 
  margin-right: 0 !important;
}
.classPageResponsiveEmbed {
  max-width: 900px;
  margin: 0 auto;
}

.pCardDeck {
  padding-bottom: 30px;
}
.pCardDeck:nth-last-child(1) {
  padding-bottom: 0;
}
.twerkButton {
  transition: 1s;
}

.courseButtonEffect {
  -webkit-animation: fadein 2s, heartbeat 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s, heartbeat 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s, heartbeat 2s; /* Internet Explorer */
    -o-animation: fadein 2s, heartbeat 2s; /* Opera < 12.1 */
       animation: fadein 2s, heartbeat 2s;
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( 1 );
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media(min-width: 992px) {
  .classCardStyle {
    margin-left: 0 !important; 
    margin-right: 0 !important;
  }
}
@media(max-width: 991px) {
  .pCardDeck:nth-child(1) {
    padding-top: 5vw;
  }
  .pCardDeck {
    padding-bottom: 5vw;
  }
  .pCardDeck {
    width: 80%;
    margin: 0 auto;
  }
}