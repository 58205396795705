.thanksCardStyle {
  background-color: transparent;
  border: none;
  margin-left: 0 !important; 
  margin-right: 0 !important;
  max-width: 80%;
}
.thanksVipCol .pCardDeck {
  display: flex;
  justify-content: center;
}
.thanksVipSectionRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thanksButtonTextP {
  width: 80%;
  margin: 0 auto 1rem auto;
}
.thanksButtonText {
  margin-right: 0 !important;
}
.buttonThanksYouCol {
  padding-top: 5vw;
  padding-bottom: 5vw;
}
.thanksPageImgCol {
  padding-bottom: 5vw;
}
.pCardDeck {
  padding-bottom: 30px;
}
.pCardDeck:nth-last-child(1) {
  padding-bottom: 0;
}

@media(min-width: 992px) {
  .thanksCardStyle {
    margin-left: 0 !important; 
    margin-right: 0 !important;
  }
}
@media(max-width: 991px) {
  .thanksCardStyle {
    max-width: 100%;
  }
  .pCardDeck:nth-child(1) {
    padding-top: 5vw;
  }
  .pCardDeck {
    padding-bottom: 5vw;
  }
  .pCardDeck {
    width: 80%;
    margin: 0 auto;
  }
  .buttonThanksYouCol {
    padding-bottom: 8vw;
  }
}