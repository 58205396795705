.joinUsSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.joinUsImg {
    width: 100%;
    // transform: rotate(-3deg) !important;
}

.joinUsContent .h2Title,
.joinUsContent .pText {
    text-align: left;
}

.joinUsContent .pText {
    margin-top: 40px;
    margin-bottom: 100px;
}


// FORM
._field11 {
    // margin-top: 50px;
    // margin-bottom: 50px !important;
}

._form {
    width: 100% !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
}

._form-label {
    display: none !important;
}

#_form_5F2D0E9113170_._inline-style {
    width: 100%;
    display: inline-block;
}

._x64724438 input {
    color: #fff;
}

._field-wrapper input {
    background-color: transparent;
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: 50px !important;
    font-size: 1.5em !important;
}

// ._inline-style input[type="text"] {
//   width: 350px !important;
//   font-size: 20px !important;
//   background-color: transparent;
//   background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-position: center;
//   border: none !important;
// }
#_form_154_submit {
    font-family: "league-gothic" !important;
    font-size: 20px !important;
    line-height: initial;
    letter-spacing: 0.4px;
    width: 100%;
    height: 64px;
    background-color: transparent !important;
    position: relative;

    margin: 10px;

    z-index: 1;
}

#_form_154_submit::after {
    content: "";
    height: 64px;
    width: 100%;

    background-image: url("../../../../assets/img/icons/Boton---Twerkealo.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% !important;

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

._row input[type="checkbox"]+span label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}

._row input[type="checkbox"]+span label {
    margin-left: 10px;
}

// ._error-inner._no_arrow {
//     display: none;
// }

._inline-style:not(._clear)+._inline-style:not(._clear) {
    margin-left: 0 !important;
}

._button-wrapper {
    margin: 0 !important;
}

._inline-style:not(._clear)+._inline-style:not(._clear) {
    margin-left: 20px !important;
}

._inline-style input[type="text"],
._inline-style input[type="date"] {
    padding: 10px 28px !important;
}

label {
    display: inline-block;
    margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    // background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

._form-thank-you {
    text-align: left !important;
}

// ._field11 {
//   position: absolute !important;
//   bottom: -40px;
// }
._form {
    padding-top: 0 !important;
}

input[type="checkbox"]._has_error {
    outline: theme-color("primary") 1px solid !important;
    animation: shake 0.5s;
}

._form_element._clear {
    margin-bottom: 0 !important;
}

.policyStyle {
    color: #fff;
    text-decoration: underline !important;
}

@media (min-width: 2000px) {
    // ._row input[type="checkbox"]+span label {
    //     font-size: 20px !important;
    // }

}

@media (min-width: 1440px) and (max-width: 2000px) {
    ._row input[type="checkbox"]+span label {
        font-size: 0.9vw !important;
    }

    input[type="checkbox"] {
        width: 0.7vw;
        height: 0.7vw;
    }
}

@media (max-width: 1013px) and (min-width: 991px) {
    ._inline-style:not(._clear)+._inline-style:not(._clear) {
        margin-left: 0 !important;
    }
}

@media (min-width: 991px) and (max-width: 1440px) {

    // FORM
    // ._inline-style input[type="text"] {
    //   width: 235px !important;
    // }
    #_form_154_submit {
        font-family: "league-gothic" !important;
        font-size: 20px !important;
        line-height: 18px !important;
        letter-spacing: 0.4px;
        height: 54px;
        background-color: transparent !important;
        position: relative;
        width: 100%;
        height: 64px;
    }

    #_form_154_submit::after {
        height: 64px;
        width: 100%;
        background-size: 100% 100% !important;
    }

    // ._field11 {
    //   position: absolute !important;
    //   bottom: -20px;
    // }
    ._form {
        padding-top: 0 !important;
    }

    ._row input[type="checkbox"]+span label {
        font-size: 1.1vw !important;
    }
}

@media (max-height: 700px) and (min-width: 991px) {

    // FORM
    // ._inline-style input[type="text"] {
    //   width: 235px !important;
    // }
    // #_form_154_submit {
    //   font-family: "league-gothic" !important;
    //   font-size: 15px !important;
    //   line-height: 15px !important;
    //   letter-spacing: 0.4px;
    //   height: 54px;
    //   background-color: transparent !important;
    //   position: relative;
    //   width: 125px;
    //   height: 74px;
    // }
    // #_form_154_submit::after {
    //   height: 74px;
    //   width: 125px;
    // }
    // ._field11 {
    //   position: absolute !important;
    //   bottom: -20px;
    // }
    ._form {
        padding-top: 0 !important;
    }

}

@media (max-height: 960px) {

    // ._field11 {
    //   position: absolute !important;
    //   bottom: -40px;
    // }
    ._form {
        padding-top: 0 !important;
    }
}

@media (max-width: 991px) {

    // FORM
    ._form {
        // margin-top: 50px !important;
        padding-right: 0 !important;
    }


    .joinUsText {
        padding-left: 0;
        padding-right: 0;
    }

    .joinUsImg {
        width: 80%;
    }

    .joinUsContent .pText {
        margin-top: 20px;
        margin-bottom: 10vw;
    }

    .joinUsTextCol {
        width: 50%;
        margin: 0 auto;
    }

    .joinUsTextCol .h2Title {
        margin-top: 10vw;
    }


}

@media (min-height: 800px) {
    .policyStyle {
        font-size: 1.3em !important;
    }
}

@media (max-width: 666px) {
    .joinUsTextCol {
        width: 60%;
    }

    .joinUsTextCol .h2Title {
        font-size: 10vw;
        line-height: 10vw;
    }

    // 
    .formContent {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    ._form_154 {
        display: flex;
        justify-content: center;
    }

    ._submit {
        left: 50%;
        transform: translate(-50%);
    }


    ._inline-style:not(._clear)+._inline-style:not(._clear) {
        margin-left: 20px !important;
    }

    .formBgSize {
        text-align: left; // CENTER ON MOBILE: center
    }



}

@media (max-width: 550px) {
    ._row input[type="checkbox"]+span label {
        font-size: 2.5vw !important;
    }

}

@media (max-width: 500px) {
    .joinUsTextCol {
        width: 70%;
    }
}

@media (max-width: 400px) {
    // ._inline-style input[type="text"] {
    //   float: none !important;
    // }

    ._button-wrapper {
        margin-top: 0 !important;
    }

    // ._row input[type="checkbox"]+span label {
    //     font-size: 3.5vw !important;
    // }
}