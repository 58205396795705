.privacityContainer {
  padding-top: 250px;
  color: theme-color("textColor");
  font-family: "league-gothic" !important;
}

.politicsTextStyle {
  font-family: "league-gothic" !important;
  font-size: 1.3em;
  color: rgba(255, 255, 255, 0.356);
  letter-spacing: 1px;
  text-align: justify;
}

.politicsTextStyle h2,
.politicsTextStyle h3 {
  color: #fff !important;
}

.politicsTextStyle .table {
  color: #fff;
}

.politicsTextStyle h3 {
  margin-top: 60px;
}

.politicsTextStyle h4 {
  margin-top: 30px;
  color: rgba(255, 255, 255, 0.774) !important;
}

.politicsTextStyle p,
.politicsTextStyle span {
  font-family: "league-gothic" !important;
}

.politicsTextStyle a:link {
  -webkit-text-decoration: none;

  text-decoration: none;
}

@media (max-width: 991px) {
  .politicsTextStyle {
    padding-right: 55px;
    padding-left: 55px;
  }
}